<template>
  <footer>
    <div class="copyright__wrapper">
      &copy; {{ new Date().getFullYear() }} Dyslexia Tutoring LTD - All Rights
      Reserved
    </div>
  </footer>
</template>

<script>
export default {
  name: "AppFooter",
};
</script>

<style lang="scss" scoped>
footer {
  background-color: #fafafa;
  @include breakpoint($desktop) {
    margin: 4rem 0 0 0 !important;
  }
}
.copyright__wrapper {
  color: #7e7e7e;
  font-size: 1.2rem;
  line-height: 1.66667;
  text-align: center;
  width: 100%;
  max-width: 86rem;
  margin: 0 auto;
  padding: 1rem;
}
</style>
