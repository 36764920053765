<script setup>
const storyblokApi = useStoryblokApi();
const { data } = await storyblokApi.get("cdn/stories/config", {
  version: useRoute().query._storyblok ? "draft" : "published",
  resolve_links: "url",
});

const hamburgerState = ref(false);

const toggleHamburger = () => {
  hamburgerState.value = !hamburgerState.value;
};

const headerMenu = ref(null);
headerMenu.value = data.story.content.header_menu;

addRouteMiddleware(() => {
  hamburgerState.value = false;
});
</script>

<template>
  <header>
    <div class="container">
      <h1>{{ data?.story?.content?.header_title }}</h1>

      <button
        class="menu-toggle"
        v-bind:class="{ active: hamburgerState }"
        @click="toggleHamburger"
      >
        <span class="line"></span>
        <span class="line"></span>
        <span class="line"></span>
      </button>
      <nav v-if="headerMenu" v-show="hamburgerState">
        <ul>
          <li v-for="blok in headerMenu" :key="blok?._uid">
            <NuxtLink
              :to="
                blok?.link?.cached_url === 'home' ? '/' : blok?.link?.cached_url
              "
              @click="toggleHamburger"
            >
              {{ blok?.link?.story?.name }}
            </NuxtLink>
          </li>
        </ul>
      </nav>
    </div>
  </header>
</template>
<script>
export default {
  name: "AppHeader",
};
</script>

<style lang="scss" scoped>
header {
  display: flex;
  flex-direction: column;
  padding: 3rem;
  background-color: $color-yellow;
}
h1 {
  font-weight: 700;
  font-size: 2.4rem;
  color: $color-white;
  margin: 0 3.5rem 0 0;
}

#menu-toggle {
  display: none;
}

.line {
  display: block;
  width: 35px;
  height: 4px;
  background-color: #ffffff;
  position: relative;
  margin: 0 auto 5px;
  transition: transform 0.3s ease;
}

.line:nth-child(1) {
  top: 0;
}

.line:nth-child(2) {
  top: 50%;
}

.line:nth-child(3) {
  bottom: 0;
}

.menu-toggle.active .line:nth-child(1) {
  transform: rotate(45deg) translate(7px, 8px);
}

.menu-toggle.active .line:nth-child(2) {
  opacity: 0;
}

.menu-toggle.active .line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -6px);
}

.menu-toggle {
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
  appearance: none;
  outline: none;
  border: 0;
  background: transparent;
  @include breakpoint($desktop) {
    display: none;
  }
}

nav {
  @include breakpoint($desktop) {
    display: block !important;
  }
  margin-top: 1.2rem;
  ul {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    @include breakpoint($mobile) {
      // display: none;
      flex-direction: column;
    }
    li {
      margin-right: 1.5rem;
      .router-link-exact-active {
        border-bottom: 1px solid $color-white;
      }
      a {
        color: $color-white;
        font-weight: 700;
        font-size: 1.6rem;
        text-decoration: none;
        &:hover {
          border-bottom: 1px solid $color-white;
        }
      }
    }
  }
}
</style>
